import React from 'react';
import { motion } from 'framer-motion';

export default function VincentV() {
  return (
    <svg width="140" height="44" viewBox="0 0 140 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.586 0.0802235L72.3389 33.8012L87.0679 0.0467937H89.7704L71.3179 43.531L52.3652 0L57.586 0.0802235Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22.3622C0 22.3622 33.9803 20.6074 34.7972 20.6074C35.1804 20.6074 36.4399 20.9447 36.4399 22.4262C36.4399 23.9076 35.121 24.3122 34.5712 24.3122C34.0214 24.3122 0 22.3622 0 22.3622Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.968 22.5582C139.968 22.5582 106.213 24.313 105.401 24.313C105.021 24.313 103.769 23.9757 103.769 22.4942C103.769 21.0128 105.08 20.6082 105.626 20.6082C106.172 20.6082 139.968 22.5582 139.968 22.5582Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function VincentVHomepage() {
  const pathVariants = {
    hidden: {
      pathLength: 0,
      fill: 'rgba(238, 232, 232, 0)',
    },
    visible: {
      pathLength: 1,
      fill: 'rgba(238, 232, 232, 1)',
      transition: {
        fill: {
          delay: 0.5,
          duration: 1,
        },
        duration: 1,
      },
    },
    exit: {
      fill: 'rgba(238, 232, 232, 0)',
      pathLength: 0,
    },
  };

  return (
    <svg width="140" height="44" viewBox="0 0 140 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.path
        variants={pathVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.586 0.0802235L72.3389 33.8012L87.0679 0.0467937H89.7704L71.3179 43.531L52.3652 0L57.586 0.0802235Z"
        fill="currentColor"
        strokeWidth="0.5"
        stroke="currentColor"
      />
      <motion.path
        variants={pathVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22.3622C0 22.3622 33.9803 20.6074 34.7972 20.6074C35.1804 20.6074 36.4399 20.9447 36.4399 22.4262C36.4399 23.9076 35.121 24.3122 34.5712 24.3122C34.0214 24.3122 0 22.3622 0 22.3622Z"
        fill="currentColor"
        strokeWidth="0.5"
        stroke="currentColor"
      />
      <motion.path
        variants={pathVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.968 22.5582C139.968 22.5582 106.213 24.313 105.401 24.313C105.021 24.313 103.769 23.9757 103.769 22.4942C103.769 21.0128 105.08 20.6082 105.626 20.6082C106.172 20.6082 139.968 22.5582 139.968 22.5582Z"
        fill="currentColor"
        strokeWidth="0.5"
        stroke="currentColor"
      />
    </svg>
  );
}
