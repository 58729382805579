import { Link } from "gatsby";
import React from "react";
import VincentV from "../../images/svgs/VincentV";
import TickerHolder from "./Ticker";
import { footerHolder } from "./Footer.module.scss";
import LazyMailingListForm from "../../components/Forms/LazyMailingListForm";
import {
  LocationSvg,
  TelephoneSvg,
  EmailSvg,
  InstagramSvg,
  FacebookSvg,
} from "../../images/svgs/FooterSvgs";
import useWhatsOn from "../../hooks/useWhatsOn";

export default function Footer() {
  const whatsOnInfo = useWhatsOn();

  return (
    <footer className={footerHolder}>
      <TickerHolder />
      <div className="footerLower">
        <div className="vHolder">
          <VincentV />
        </div>
        <div className="footerBlocks">
          <div className="footerBlock footerSocials">
            <h3>Follow Us</h3>
            <ul className="links linksWithIcons">
              <li>
                <a
                  className="facebook"
                  href="https://www.facebook.com/thevincenthotel/"
                  title="The Vincent Hotel on Facebook"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookSvg />
                  Facebook
                </a>
              </li>
              <li>
                <a
                  className="instagram"
                  href="https://www.instagram.com/thevincent.southport/"
                  title="The Vincent Hotel on Instagram"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramSvg />
                  Instagram
                </a>
              </li>
            </ul>
          </div>
          <div className="footerBlock footerContact">
            <h3>Contact Us</h3>
            <ul className=" links linksWithIcons">
              <li>
                <a
                  className="telephone"
                  href="tel:+441704883800"
                  title="Call The Vincent Hotel"
                >
                  <TelephoneSvg />
                  01704 883 800
                </a>
              </li>
              <li>
                <a
                  className="mail"
                  href="mailto:enquiries@thevincenthotel.com"
                  title="Email The Vincent Hotel"
                >
                  <EmailSvg />
                  enquiries@thevincenthotel.com
                </a>
              </li>
              <li>
                <a
                  className="location"
                  href="https://www.thevincenthotel.com/contact-us"
                  title="The Vincent Hotel on Google Maps"
                >
                  <LocationSvg />
                  98 Lord St, Southport, PR8 1JR
                </a>
              </li>
            </ul>
          </div>
          <div className="footerBlock footerInternalLinks">
            <h3>Find Out More</h3>
            <ul className="links">
              <li>
                <Link to="/our-rooms" title="Our Rooms">
                  Our Rooms
                </Link>
              </li>
              <li>
                <Link to="/food-and-drink" title="Food & Drink">
                  Food & Drink
                </Link>
              </li>
              <li>
                <Link to="/weddings-and-events" title="Weddings & Events">
                  Weddings & Events
                </Link>
              </li>

              <li>
                <Link to="/health-and-beauty" title="Health & Beauty">
                  Health & Beauty
                </Link>
              </li>

              {whatsOnInfo.whatsOnPageOptions.showWhatsOnLinkInMenu && (
                <li>
                  <Link
                    to="/whats-on"
                    title="What's On at The Vincent"
                    activeClassName="active"
                  >
                    What's On?
                  </Link>
                </li>
              )}
              <li>
                <Link to="/contact" title="Contact the Vincent Hotel">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="footerBlock footerMailingList">
            <h3>Stay In Touch</h3>
            <LazyMailingListForm id="footerMailingListForm" />
          </div>
        </div>
        <div className="legalAndCredit">
          <ul>
            <li>© {new Date().getFullYear()} The Vincent Hotel</li>
            <li>
              <Link
                to="/policies/terms-and-conditions"
                titl="Terms & Conditions"
              >
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link to="/policies/green-story" title="Green Story">
                Green Story
              </Link>
            </li>
            <li>
              <Link to="/policies/privacy-policy" title="Privacy Policy">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to="/policies/responsible-guest-charter"
                title="Responsible Guest Charter"
              >
                Responsible Guest Charter
              </Link>
            </li>
            <li>
              <Link
                to="/policies/sustainability-policy"
                title="Sustainability Policy"
              >
                Sustainability Policy
              </Link>
            </li>{" "}
            <li>
              <Link
                to="/policies/accessibility-policy"
                title="Accessibility Policy"
              >
                Accessibility Policy
              </Link>
            </li>
            <li>
              <a
                href="mailto:ben@lookupstudios.co.uk"
                title="Email Ben at Look Up!"
              >
                Site by Look Up!
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
