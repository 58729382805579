import { useStaticQuery, graphql } from 'gatsby';

export default function useWhatsOn() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      wpPage(id: { eq: "cG9zdDo1OTA=" }) {
        title
        whatsOnPageOptions {
          rotatingBadgeText
          showWhatsOnLinkInMenu
          showWhatsOnRotatingBadge
        }
      }
    }
  `);

  return data.wpPage;
}
