import React, { useState, useMemo, createContext } from 'react';

export const BookContext = createContext();

export default function ContextsProvider({ children }) {
  const [bookingModalDetails, seBookingModalDetails] = useState({ show: false });

  const bookingModalDetailsMemo = useMemo(() => [bookingModalDetails, seBookingModalDetails], [bookingModalDetails, seBookingModalDetails]);

  return <BookContext.Provider value={bookingModalDetailsMemo}>{children}</BookContext.Provider>;
}
