import { Link } from 'gatsby';
import React from 'react';
import VincentV from '../images/svgs/VincentV';
import { headerHolder } from './Header.module.scss';
import Nav from './Nav';

export default function Header() {
  return (
    <header className={headerHolder}>
      <h3>The Vincent Hotel</h3>
      <Link to="/" title="The Vincent Hotel homepage" className="vHolder">
        <VincentV />
      </Link>

      <Nav />
    </header>
  );
}
