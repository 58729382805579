import React, { useEffect, useState, useMemo } from "react";
import Header from "./Header";
import Footer from "./Footer/Footer";
import "../styles/normalize.css";
import "../styles/styles.scss";
import { AnimatePresence, motion } from "framer-motion";
import { weddingsLayout } from "../pages/weddings-and-events/weddingsAndEvents.module.scss";
import hydrateImages from "../utils/hydrateImages";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BookingsModal from "../components/BookingsModal";

import isBrowser from "../utils/isBrowser";
import LazyRoomBookingForm from "../components/Forms/LazyRoomBookingForm";

const lightGrey = "#eee8e8";
const midGrey = "#444";
const darkGrey = "#262d32";

const mainVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  exit: {
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const theme = createTheme({
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          "&::before": {
            borderColor: lightGrey,
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: darkGrey,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: lightGrey,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "2rem",
          textTransform: "none",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: lightGrey,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: lightGrey,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: midGrey,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: midGrey,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: darkGrey,
        },
      },
    },
  },
  shape: {
    borderRadius: "0rem",
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: '"Playfair Display", serif',
  },
  palette: {
    primary: {
      main: lightGrey,
    },
    secondary: {
      main: lightGrey,
    },
    text: {
      primary: lightGrey,
      secondary: lightGrey,
    },
  },
});

export default function Layout({ children, location }) {
  const [previousLocation, setPreviousLocation] = useState(null);

  useEffect(() => {
    setPreviousLocation(location);
  }, [location, setPreviousLocation]);

  const comingFromSameSection = useMemo(() => {
    if (!location || !previousLocation) return false;

    const pathArray = location.pathname.split("/");
    const previousPathArray = previousLocation?.pathname.split("/");
    if (previousPathArray && previousPathArray.length > 1) {
      return previousPathArray[1] === pathArray[1];
    } else {
      return false;
    }
  }, [location, previousLocation]);

  return (
    <div
      className={`pageHolder ${
        location.pathname === "/weddings-and-events/" ? weddingsLayout : ""
      }`}>
      <ThemeProvider theme={theme}>
        <Header />
        <AnimatePresence
          exitBeforeEnter
          custom={comingFromSameSection}
          onExitComplete={() => {
            setTimeout(() => hydrateImages(), 100);
            //scroll to top of page
            if (isBrowser()) {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }
          }}>
          <motion.main
            key={children?.props?.path}
            variants={mainVariants}
            initial={comingFromSameSection ? "visible" : "hidden"}
            animate="visible"
            exit="exit">
            {children}
          </motion.main>
        </AnimatePresence>
        <Footer />
        <div style={{ display: "none" }}>
          <LazyRoomBookingForm />
        </div>
        <BookingsModal />
      </ThemeProvider>
    </div>
  );
}
