import React, { useEffect } from 'react';
import { tickerHolder } from './Ticker.module.scss';
import { Link } from 'gatsby';
import { motion, useAnimationControls } from 'framer-motion';

const tickerLinks = [
  {
    text: 'Sushi',
    to: '/food-and-drink',
  },
  {
    text: 'Cocktails',
    to: '/food-and-drink/the-vincent-bar',
  },
  {
    text: 'Enhanced Wifi',
    to: '/our-rooms',
  },
  {
    text: 'Rooms',
    to: '/our-rooms',
  },
  {
    text: 'Meetings',
    to: '/weddings-and-events/meetings',
  },
  {
    text: 'Health & Beauty',
    to: '/health-and-beauty',
  },
  {
    text: 'Weddings',
    to: '/weddings-and-events',
  },
  {
    text: 'Gift Vouchers',
    to: '/vouchers',
  },
];

const descriptionVariants = {
  hidden: {
    opacity: 0,
    y: -20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const tickerVariants = {
  scrolling: {
    x: '-100%',
    transition: {
      duration: 20,
      type: 'tween',
      ease: 'linear',
      repeat: Infinity,
    },
  },
};

const Ticker = ({ controls }) => {
  return (
    <motion.ul
      variants={tickerVariants}
      initial="paused"
      animate={controls}
      onMouseEnter={() => controls.stop()}
      onMouseLeave={() => controls.start('scrolling')}>
      {tickerLinks.map((link, index) => {
        return (
          <li key={index}>
            {link.to ? (
              <Link to={link.to} title={link.text}>
                {link.text}
              </Link>
            ) : (
              <div className="linkWithDescription">
                <button title={link.text}>{link.text}</button>
                <motion.div className="description" variants={descriptionVariants} initial="hidden" animate="hidden" custom={index}>
                  {link.description}
                </motion.div>
              </div>
            )}
          </li>
        );
      })}
    </motion.ul>
  );
};

export default function TickerHolder() {
  const controls = useAnimationControls();

  useEffect(() => {
    controls.start('scrolling');
  }, [controls]);

  return (
    <div className={tickerHolder}>
      <Ticker controls={controls} />
      <Ticker controls={controls} />
      <Ticker controls={controls} />
    </div>
  );
}
