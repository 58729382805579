import React from "react";
import { motion } from "framer-motion";

export default function OfferBadge() {
  const svgVariants = {
    initial: {
      rotate: 0,
    },
    animate: {
      rotate: 360,
      transition: {
        duration: 10,
        repeat: Infinity,
        ease: "linear",
      },
    },
  };
  return (
    <motion.svg
      width="216"
      height="216"
      viewBox="0 0 216 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial="initial"
      animate="animate"
      variants={svgVariants}>
      <path
        d="M211.364 79.7036C226.73 137.051 192.697 195.997 135.35 211.364C78.0021 226.73 19.0559 192.697 3.68965 135.35C-11.6766 78.0021 22.356 19.0559 79.7036 3.68965C137.051 -11.6766 195.997 22.356 211.364 79.7036Z"
        fill="#4f6f78"
        fillOpacity="0.65"
      />
      <path
        d="M66.61 190.658L66.9107 191.765L65.7933 191.513L65.657 192.444L66.8113 192.494L66.2162 193.493L67.0617 193.904L67.475 192.829L68.2327 193.696L68.89 193.019L67.9921 192.29L69.048 191.844L68.6134 191.012L67.6378 191.644L67.5321 190.487L66.61 190.658Z"
        fill="#EEE8E8"
      />
      <path
        d="M59.9688 181.416L55.7464 178.509L55.1885 179.319L58.4025 181.532L57.0573 183.486L54.1853 181.508L53.6336 182.31L56.5055 184.287L55.3028 186.034L52.0888 183.821L51.5309 184.631L55.7532 187.538L59.9688 181.416Z"
        fill="#EEE8E8"
      />
      <path
        d="M48.8955 172.846L47.8212 171.916L47.3034 176.572L43.1043 177.664L44.0876 178.516L46.539 177.905L47.1601 177.706L47.0514 178.349L46.8228 180.884L47.8971 181.814L48.3837 177.478L52.9032 176.317L51.9199 175.465L49.0874 176.165L48.5258 176.329L48.6164 175.757L48.8955 172.846Z"
        fill="#EEE8E8"
      />
      <path
        d="M37.2185 168.375C36.3243 169.679 36.5911 171.159 37.593 172.242C39.0474 173.815 41.022 173.761 42.7717 172.143C44.5294 170.518 44.7212 168.584 43.2816 167.027C42.2872 165.952 40.8312 165.601 39.4732 166.306L40.3488 167.253C41.1598 166.89 41.9483 167.099 42.5271 167.725C43.4547 168.728 43.2179 170.034 41.9257 171.229C40.6335 172.423 39.2898 172.564 38.3697 171.569C37.7686 170.919 37.6313 170.078 38.0867 169.314L37.2185 168.375Z"
        fill="#EEE8E8"
      />
      <path
        d="M38.4642 161.578L35.5926 157.707L34.7936 158.299L36.9359 161.188L31.7647 165.023L32.494 166.006L38.4642 161.578Z"
        fill="#EEE8E8"
      />
      <path
        d="M28.1092 150.961L24.1446 153.298L24.7663 154.352L28.7308 152.016C29.795 151.389 30.7833 151.58 31.3605 152.56C31.9322 153.529 31.6359 154.491 30.5624 155.123L26.5978 157.46L27.2194 158.515L31.184 156.178C32.7754 155.24 33.1571 153.67 32.208 152.06C31.259 150.45 29.7007 150.023 28.1092 150.961Z"
        fill="#EEE8E8"
      />
      <path
        d="M23.1077 147.088C22.5683 147.328 22.0189 147.034 21.6726 146.255C21.3175 145.456 21.4517 144.762 22.2064 144.199L21.6781 143.01C20.2963 143.912 20.1454 145.295 20.7404 146.633C21.4285 148.181 22.6894 148.65 23.7082 148.197C26.4351 146.985 23.1775 143.504 25.0953 142.652C25.7047 142.381 26.3317 142.688 26.7446 143.617C27.1487 144.526 26.9822 145.282 26.2974 145.814L26.8169 146.982C28.111 146.072 28.3384 144.727 27.668 143.219C27.0065 141.731 25.7734 140.975 24.4349 141.57C21.6581 142.804 24.8957 146.293 23.1077 147.088Z"
        fill="#EEE8E8"
      />
      <path
        d="M25.0243 136.973L24.6247 135.816L17.5988 138.242L17.9984 139.399L25.0243 136.973Z"
        fill="#EEE8E8"
      />
      <path
        d="M15.8665 132.665L16.1759 133.917L22.7023 129.343L22.3615 127.964L14.4585 126.966L14.7496 128.144L20.6251 128.866L21.3992 128.9L20.73 129.29L15.8665 132.665Z"
        fill="#EEE8E8"
      />
      <path
        d="M21.0088 121.267L20.3315 116.186L19.3563 116.316L19.8719 120.184L17.5207 120.497L17.06 117.041L16.0957 117.169L16.5563 120.626L14.4543 120.906L13.9388 117.038L12.9636 117.168L13.6409 122.249L21.0088 121.267Z"
        fill="#EEE8E8"
      />
      <path
        d="M20.1753 102.82C20.2812 100.626 18.884 99.1904 16.5039 99.0755C14.1237 98.9607 12.573 100.254 12.4671 102.448C12.3601 104.665 13.7797 106.09 16.1598 106.205C18.54 106.32 20.0684 105.037 20.1753 102.82ZM19.149 102.771C19.0789 104.223 17.9668 105.045 16.2199 104.96C14.4621 104.876 13.4233 103.95 13.4934 102.498C13.5635 101.046 14.686 100.235 16.4438 100.32C18.1907 100.405 19.2191 101.319 19.149 102.771Z"
        fill="#EEE8E8"
      />
      <path
        d="M20.6762 95.4451L20.8742 94.237L17.7891 93.7313L18.3531 90.2903L17.3823 90.1312L16.8183 93.5722L14.5099 93.1938L15.1411 89.3429L14.1703 89.1838L13.3411 94.2429L20.6762 95.4451Z"
        fill="#EEE8E8"
      />
      <path
        d="M22.3017 86.7183L22.6213 85.5364L19.6034 84.7205L20.5135 81.3544L19.5638 81.0976L18.6537 84.4637L16.3956 83.8531L17.4141 80.0861L16.4645 79.8293L15.1264 84.7782L22.3017 86.7183Z"
        fill="#EEE8E8"
      />
      <path
        d="M24.9778 77.714L26.823 72.931L25.9052 72.5769L24.5006 76.2177L22.2876 75.3639L23.5426 72.1106L22.635 71.7605L21.3799 75.0137L19.4015 74.2505L20.806 70.6097L19.8882 70.2556L18.043 75.0386L24.9778 77.714Z"
        fill="#EEE8E8"
      />
      <path
        d="M28.3453 69.5177L28.905 68.4289L26.2316 67.0546L26.8963 65.7616C27.2712 65.0325 27.5669 64.8158 28.4151 65.0306L30.3894 65.5417L30.9791 64.3945L28.741 63.8708C28.073 63.7117 27.6034 63.7407 27.1993 64.0246C27.2126 63.2817 26.8625 62.6715 26.114 62.2867C24.9474 61.687 23.7438 62.2113 23.0091 63.6403L21.7347 66.1193L28.3453 69.5177ZM23.869 64.1193C24.2739 63.3318 24.9365 63.0948 25.5976 63.4347C26.2683 63.7795 26.4612 64.4563 26.0464 65.2632L25.3567 66.6048L23.1694 65.4803L23.869 64.1193Z"
        fill="#EEE8E8"
      />
      <path
        d="M33.9061 51.3201L32.7638 51.419L33.2104 50.3643L32.3192 50.0644L32.0637 51.1913L31.1866 50.4278L30.6316 51.1865L31.616 51.7847L30.6283 52.3759L31.1775 53.1433L32.0539 52.3895L32.3056 53.5081L33.2008 53.2285L32.753 52.156L33.9107 52.258L33.9061 51.3201Z"
        fill="#EEE8E8"
      />
      <path
        d="M38.0832 46.1558L36.9368 46.1715L37.4587 45.1519L36.5916 44.7882L36.255 45.8935L35.4357 45.0683L34.827 45.7847L35.7654 46.4529L34.7374 46.9707L35.2294 47.776L36.1583 47.0878L36.3281 48.2217L37.2413 48.0079L36.8724 46.9057L38.0198 47.0915L38.0832 46.1558Z"
        fill="#EEE8E8"
      />
      <path
        d="M48.7479 41.9138L52.6242 38.5587L51.9803 37.8149L49.0298 40.3687L47.4774 38.5752L50.1139 36.2932L49.4773 35.5576L46.8407 37.8397L45.4529 36.2363L48.4035 33.6824L47.7597 32.9386L43.8834 36.2936L48.7479 41.9138Z"
        fill="#EEE8E8"
      />
      <path
        d="M59.9695 33.2088L61.1399 32.4029L56.7638 30.7314L56.7621 26.3926L55.6908 27.1303L55.6651 29.6564L55.7022 30.3077L55.107 30.0407L52.7109 29.1823L51.5405 29.9883L55.6148 31.5491L55.6031 36.2156L56.6745 35.4778L56.7094 32.5605L56.6915 31.9756L57.2226 32.2071L59.9695 33.2088Z"
        fill="#EEE8E8"
      />
      <path
        d="M66.9299 23.1914C65.8944 21.9975 64.3947 21.8826 63.0936 22.5788C61.2046 23.5896 60.7591 25.5141 61.8834 27.6151C63.0129 29.7258 64.8361 30.3991 66.7058 29.3985C67.9972 28.7075 68.704 27.3871 68.3644 25.8951L67.2271 26.5037C67.3736 27.3799 66.9727 28.0903 66.221 28.4926C65.0163 29.1373 63.8125 28.5789 62.9821 27.0272C62.1518 25.4755 62.3544 24.1397 63.5495 23.5002C64.3302 23.0825 65.1784 23.1617 65.8023 23.7948L66.9299 23.1914Z"
        fill="#EEE8E8"
      />
      <path
        d="M73.3743 25.994L77.8474 24.1971L77.4767 23.2741L74.1396 24.6146L71.7397 18.6403L70.6037 19.0967L73.3743 25.994Z"
        fill="#EEE8E8"
      />
      <path
        d="M86.2308 18.66L84.9805 14.2312L83.8023 14.5638L85.0526 18.9926C85.3882 20.1814 84.9514 21.0884 83.8574 21.3972C82.7738 21.7031 81.9197 21.1719 81.5811 19.9727L80.3308 15.5439L79.1526 15.8765L80.4029 20.3053C80.9048 22.0831 82.3258 22.8518 84.1246 22.344C85.9235 21.8362 86.7327 20.4378 86.2308 18.66Z"
        fill="#EEE8E8"
      />
      <path
        d="M91.173 14.7818C91.0768 14.1994 91.4999 13.7418 92.3411 13.603C93.2039 13.4606 93.8414 13.7653 94.1958 14.6374L95.4792 14.4256C94.9551 12.8613 93.6549 12.3669 92.2097 12.6054C90.538 12.8813 89.7672 13.9835 89.9487 15.0836C90.4347 18.0279 94.6235 15.7522 94.9653 17.8229C95.0739 18.4808 94.6184 19.0102 93.6154 19.1758C92.634 19.3378 91.9445 18.9863 91.6025 18.1896L90.3407 18.3979C90.8954 19.8795 92.1399 20.4386 93.7684 20.1698C95.3754 19.9045 96.4174 18.9016 96.1789 17.4564C95.684 14.4582 91.4916 16.7123 91.173 14.7818Z"
        fill="#EEE8E8"
      />
      <path
        d="M100.987 19.1099L102.208 19.0184L101.652 11.6062L100.431 11.6977L100.987 19.1099Z"
        fill="#EEE8E8"
      />
      <path
        d="M107.422 11.3343L106.132 11.3167L108.905 18.7884L110.326 18.8079L113.291 11.4148L112.078 11.3982L109.893 16.8998L109.664 17.64L109.456 16.8938L107.422 11.3343Z"
        fill="#EEE8E8"
      />
      <path
        d="M117.122 19.1749L122.209 19.8116L122.331 18.8355L118.459 18.3508L118.754 15.9971L122.214 16.4302L122.335 15.4649L118.875 15.0318L119.138 12.9276L123.01 13.4123L123.133 12.4362L118.046 11.7994L117.122 19.1749Z"
        fill="#EEE8E8"
      />
      <path
        d="M135.061 23.0219C137.157 23.6819 138.9 22.6953 139.616 20.4224C140.332 18.1496 139.475 16.3212 137.38 15.6611C135.263 14.9945 133.524 16.0053 132.808 18.2782C132.092 20.551 132.945 22.3553 135.061 23.0219ZM135.37 22.0418C133.983 21.6051 133.471 20.3207 133.996 18.6525C134.525 16.9739 135.684 16.2044 137.071 16.6412C138.458 17.0779 138.956 18.3695 138.427 20.0481C137.902 21.7162 136.757 22.4786 135.37 22.0418Z"
        fill="#EEE8E8"
      />
      <path
        d="M141.644 25.1946L142.764 25.6894L144.027 22.83L147.217 24.2395L147.614 23.3397L144.425 21.9302L145.371 19.7906L148.94 21.368L149.337 20.4682L144.648 18.3959L141.644 25.1946Z"
        fill="#EEE8E8"
      />
      <path
        d="M149.941 29.0686L151.004 29.6756L152.554 26.9606L155.582 28.6893L156.07 27.8349L153.042 26.1062L154.201 24.0747L157.59 26.0094L158.078 25.155L153.626 22.6134L149.941 29.0686Z"
        fill="#EEE8E8"
      />
      <path
        d="M158.324 34.1491L162.484 37.1458L163.059 36.3475L159.893 34.0665L161.279 32.1419L164.109 34.1802L164.677 33.3908L161.848 31.3526L163.088 29.632L166.254 31.913L166.829 31.1148L162.669 28.1182L158.324 34.1491Z"
        fill="#EEE8E8"
      />
      <path
        d="M165.344 39.442L166.257 40.2581L168.261 38.0175L169.344 38.9867C169.956 39.5332 170.091 39.874 169.669 40.6407L168.677 42.4224L169.638 43.2822L170.709 41.2485C171.031 40.6422 171.122 40.1804 170.949 39.7178C171.665 39.9179 172.343 39.7329 172.904 39.1056C173.779 38.1279 173.575 36.8309 172.377 35.7598L170.299 33.9016L165.344 39.442ZM171.697 36.4712C172.357 37.0614 172.419 37.7624 171.924 38.3165C171.421 38.8787 170.717 38.8947 170.041 38.2898L168.917 37.2842L170.556 35.451L171.697 36.4712Z"
        fill="#EEE8E8"
      />
      <path
        d="M181.246 49.0373L181.434 47.9063L182.345 48.6012L182.857 47.8126L181.829 47.2848L182.787 46.6253L182.19 45.899L181.366 46.7036L181.039 45.5998L180.159 45.9408L180.671 46.9773L179.525 46.9427L179.573 47.8794L180.723 47.7124L180.336 48.8084L181.246 49.0373Z"
        fill="#EEE8E8"
      />
      <path
        d="M185.577 54.8889L185.852 53.7759L186.706 54.5391L187.278 53.7924L186.294 53.1868L187.3 52.6032L186.761 51.8329L185.877 52.5714L185.636 51.4457L184.732 51.7177L185.163 52.7906L184.023 52.6676L183.998 53.6052L185.158 53.5276L184.688 54.5904L185.577 54.8889Z"
        fill="#EEE8E8"
      />
      <path
        d="M186.869 66.1286L189.134 70.7279L190.016 70.2933L188.292 66.7923L190.42 65.7445L191.961 68.8728L192.834 68.443L191.293 65.3148L193.196 64.378L194.92 67.8789L195.802 67.4443L193.537 62.8451L186.869 66.1286Z"
        fill="#EEE8E8"
      />
      <path
        d="M192.365 78.997L192.849 80.3329L195.572 76.5208L199.77 77.6149L199.327 76.392L196.889 75.7292L196.25 75.6006L196.658 75.0921L198.094 72.9904L197.61 71.6545L195.07 75.2025L190.558 74.0128L191.001 75.2358L193.815 76.0063L194.386 76.1367L194.028 76.592L192.365 78.997Z"
        fill="#EEE8E8"
      />
      <path
        d="M200.417 88.8024C201.836 88.1063 202.331 86.6859 201.99 85.2501C201.496 83.1655 199.749 82.2429 197.43 82.7929C195.101 83.3455 193.984 84.936 194.474 86.9994C194.812 88.4246 195.908 89.4453 197.437 89.4982L197.139 88.2432C196.255 88.1609 195.67 87.5918 195.473 86.7622C195.158 85.4328 196.006 84.4116 197.718 84.0054C199.43 83.5992 200.67 84.1365 200.983 85.4553C201.187 86.3168 200.894 87.1166 200.122 87.558L200.417 88.8024Z"
        fill="#EEE8E8"
      />
      <path
        d="M195.979 93.919L196.599 98.6996L197.585 98.5718L197.123 95.0053L203.508 94.1781L203.351 92.964L195.979 93.919Z"
        fill="#EEE8E8"
      />
      <path
        d="M199.858 108.763L204.459 108.676L204.436 107.452L199.835 107.539C198.6 107.562 197.833 106.91 197.812 105.773C197.79 104.648 198.521 103.956 199.767 103.932L204.368 103.846L204.345 102.621L199.743 102.708C197.896 102.743 196.793 103.923 196.828 105.792C196.863 107.661 198.011 108.798 199.858 108.763Z"
        fill="#EEE8E8"
      />
      <path
        d="M202.324 114.598C202.911 114.653 203.246 115.179 203.166 116.027C203.084 116.898 202.627 117.437 201.693 117.557L201.571 118.853C203.217 118.744 204.027 117.613 204.164 116.154C204.323 114.468 203.454 113.441 202.344 113.337C199.373 113.057 200.506 117.687 198.417 117.49C197.753 117.428 197.357 116.852 197.453 115.84C197.546 114.85 198.061 114.273 198.919 114.145L199.039 112.872C197.465 113.031 196.607 114.092 196.452 115.735C196.299 117.356 197.004 118.62 198.462 118.757C201.488 119.042 200.376 114.414 202.324 114.598Z"
        fill="#EEE8E8"
      />
      <path
        d="M195.69 122.506L195.471 123.711L202.784 125.04L203.003 123.835L195.69 122.506Z"
        fill="#EEE8E8"
      />
      <path
        d="M201.531 130.865L201.877 129.623L193.945 130.395L193.564 131.764L199.954 136.519L200.28 135.351L195.519 131.833L194.862 131.422L195.637 131.412L201.531 130.865Z"
        fill="#EEE8E8"
      />
      <path
        d="M191.473 138.181L189.564 142.94L190.477 143.306L191.93 139.684L194.132 140.567L192.834 143.803L193.737 144.165L195.035 140.929L197.003 141.718L195.55 145.34L196.463 145.706L198.372 140.948L191.473 138.181Z"
        fill="#EEE8E8"
      />
      <path
        d="M183.308 154.317C182.145 156.182 182.665 158.116 184.687 159.377C186.709 160.638 188.693 160.266 189.856 158.402C191.03 156.519 190.486 154.582 188.464 153.321C186.443 152.06 184.482 152.435 183.308 154.317ZM184.18 154.861C184.949 153.628 186.321 153.453 187.805 154.378C189.298 155.31 189.754 156.624 188.984 157.858C188.215 159.091 186.839 159.251 185.346 158.32C183.862 157.394 183.41 156.095 184.18 154.861Z"
        fill="#EEE8E8"
      />
      <path
        d="M179.217 160.564L178.455 161.522L180.901 163.468L178.731 166.197L179.501 166.809L181.671 164.08L183.502 165.536L181.073 168.59L181.842 169.203L185.034 165.191L179.217 160.564Z"
        fill="#EEE8E8"
      />
      <path
        d="M173.434 167.483L172.58 168.36L174.82 170.541L172.388 173.04L173.093 173.726L175.525 171.227L177.201 172.859L174.479 175.655L175.184 176.341L178.76 172.668L173.434 167.483Z"
        fill="#EEE8E8"
      />
      <path
        d="M166.649 174.071L162.711 177.353L163.341 178.108L166.339 175.61L167.857 177.432L165.178 179.665L165.801 180.412L168.48 178.18L169.838 179.809L166.84 182.307L167.47 183.063L171.408 179.781L166.649 174.071Z"
        fill="#EEE8E8"
      />
      <path
        d="M159.615 179.635L158.595 180.312L160.258 182.816L159.047 183.62C158.364 184.074 158 184.119 157.364 183.517L155.891 182.108L154.816 182.821L156.514 184.371C157.019 184.835 157.444 185.039 157.935 184.989C157.561 185.631 157.568 186.334 158.034 187.035C158.76 188.128 160.066 188.258 161.405 187.369L163.727 185.827L159.615 179.635ZM160.888 186.531C160.15 187.021 159.456 186.905 159.045 186.285C158.628 185.657 158.79 184.972 159.546 184.47L160.802 183.636L162.163 185.685L160.888 186.531Z"
        fill="#EEE8E8"
      />
      <path
        d="M146.19 192.616L147.236 193.085L146.333 193.79L146.967 194.485L147.738 193.624L148.133 194.717L148.987 194.324L148.417 193.323L149.568 193.286L149.461 192.348L148.328 192.581L148.652 191.481L147.733 191.291L147.604 192.446L146.641 191.794L146.19 192.616Z"
        fill="#EEE8E8"
      />
    </motion.svg>
  );
}
