import React, { Suspense, useEffect, useState } from "react";

export const SuspenseHelper = ({ fallback, children }) => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setMounted(true);
    }
  });

  return (
    <Suspense fallback={fallback}>{!isMounted ? fallback : children}</Suspense>
  );
};
