// a component that loads the MailingListForm component lazily using React.lazy() and React.Suspense.

import React, { lazy } from "react";
import { SuspenseHelper } from "../SuspenseHelper";

const MailingListForm = lazy(() =>
  wait(0).then(() => import("./MailingListForm"))
);

export default function LazyMailingListForm() {
  return (
    <div>
      <SuspenseHelper fallback={<p>Loading form...</p>}>
        <MailingListForm />
      </SuspenseHelper>
    </div>
  );
}

function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
