import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import { bookingsModalHolder } from "./BookingsModal.module.scss";
import { BookContext } from "./SiteContexts";
import { motion } from "framer-motion";
import LazyRoomBookingForm from "./Forms/LazyRoomBookingForm";
import LazyOnlineBookingForm from "./Forms/LazyOnlineBookingForm";
import { withPrefix } from "gatsby";
import useScript from "../hooks/useScript";
import { contactIconLinks } from "../pages/contact.module.scss";
import isBrowser from "../utils/isBrowser";

const modalVariants = {
  initial: (scrollY) => ({
    opacity: 0,
    y: 100 + scrollY,
  }),
  visible: (scrollY) => ({
    opacity: 1,
    y: scrollY,
  }),
  exit: (scrollY) => ({
    opacity: 0,
    y: 100 + scrollY,
  }),
};

const shrowdVariants = {
  initial: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
};

const CloseModal = ({ setBookModal }) => {
  return (
    <button
      onClick={() => setBookModal({ show: false })}
      className="closeModal">
      <svg
        width="53"
        height="53"
        viewBox="0 0 53 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L52 52" stroke="currentColor" />
        <path d="M52 1L0.999998 52" stroke="currentColor" />
      </svg>
    </button>
  );
};

const BookARoom = ({ setBookModal, bookModal, scrollY }) => {
  return (
    <motion.div
      className="bookingsModalContent room"
      onClick={(e) => e.stopPropagation()}
      variants={modalVariants}
      custom={scrollY}>
      <h2>Make A Booking</h2>
      <h4>There are two ways to book:</h4>
      <div className="lrHolder">
        <section className="lhs">
          <h6 className="bestDeals">Best Deals!</h6>
          <div className="directBooking">
            <h5>Book direct for the best rate.</h5>
            <p className="roomUpgrade">
              Receive a complimentary room upgrade on arrival!
            </p>
            <p>(subject to availability)</p>
          </div>
          <div className={contactIconLinks}>
            <ul>
              <li className="info email">
                <a
                  href="mailto:enquiries@thevincenthotel.com"
                  title="Call The Vincent Hotel, Southport"
                  className="button">
                  <svg
                    width="24"
                    height="18"
                    viewBox="0 0 24 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.96 8.93333L22.12 15.6667M1.88 15.6667L8.48 8.93333M1.88 1.88889L12.44 11.2222L21.68 1.88889M23 15V3C23 1.89333 22.1335 1 21.06 1H2.94004C1.86655 1 1 1.89333 1 3V15C1 16.1067 1.86655 17 2.94004 17H21.06C22.1335 17 23 16.1067 23 15Z"
                      stroke="currentColor"
                      strokeWidth="0.992949"
                    />
                  </svg>
                  <span>Email us</span>
                </a>{" "}
                <a
                  href="mailto:enquiries@thevincenthotel.com"
                  title="Call The Vincent Hotel, Southport">
                  enquiries@thevincenthotel.com
                </a>
              </li>
              <li className="info call">
                <a
                  href="tel:+441704883800"
                  title="Call The Vincent Hotel, Southport"
                  className="button">
                  <svg
                    width="20"
                    height="26"
                    viewBox="0 0 20 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.03845 1.52658L5.42191 1.11377C6.71922 0.727369 8.10524 1.35258 8.66068 2.57419L9.76642 5.00782C10.2473 6.06743 9.97985 7.31425 9.10555 8.08946L6.66907 10.2471C6.8195 11.5383 7.28494 12.8091 8.0641 14.0595C8.804 15.2693 9.79646 16.3282 10.9827 17.1736L13.9091 16.2616C15.0174 15.9171 16.226 16.3144 16.9048 17.2468L18.4902 19.4188C19.2809 20.5036 19.1382 21.9988 18.1571 22.918L17.1067 23.9032C16.0601 24.8837 14.5648 25.2401 13.1826 24.8369C9.91685 23.8864 6.91722 21.0652 4.1786 16.3732C1.43612 11.6739 0.467956 7.68505 1.2754 4.41141C1.61483 3.0338 2.66528 1.93578 4.03845 1.52658Z"
                      stroke="currentColor"
                      strokeWidth="0.992949"
                    />
                  </svg>
                  <span>Call us</span>
                </a>{" "}
                <a
                  href="tel:+441704883800"
                  title="Call The Vincent Hotel, Southport">
                  01704 883 800
                </a>
              </li>
            </ul>
          </div>
          <LazyRoomBookingForm room={bookModal.type} />
        </section>
        <section className="rhs">
          <h5>Alternatively, use the ONLINE BOOKING SERVICE</h5>
          <LazyOnlineBookingForm />
        </section>
      </div>
      <CloseModal setBookModal={setBookModal} />
    </motion.div>
  );
};

const BookATable = ({ setBookModal, scrollY }) => {
  useScript(withPrefix("/js/WidgetV2Loader.js"));

  return (
    <motion.div
      className="bookingsModalContent room"
      onClick={(e) => e.stopPropagation()}
      variants={modalVariants}
      custom={scrollY}>
      <h2>Make A Booking</h2>

      <h4>There are two ways to book:</h4>
      <div className="lrHolder">
        <section className="lhs">
          <div className="directBooking">
            <h5>Book direct with us</h5>
          </div>
          <div className={contactIconLinks}>
            <ul>
              <li className="info email">
                <a
                  href="mailto:cafe@thevincenthotel.com"
                  title="Call The Vincent Hotel, Southport"
                  className="button">
                  <svg
                    width="24"
                    height="18"
                    viewBox="0 0 24 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.96 8.93333L22.12 15.6667M1.88 15.6667L8.48 8.93333M1.88 1.88889L12.44 11.2222L21.68 1.88889M23 15V3C23 1.89333 22.1335 1 21.06 1H2.94004C1.86655 1 1 1.89333 1 3V15C1 16.1067 1.86655 17 2.94004 17H21.06C22.1335 17 23 16.1067 23 15Z"
                      stroke="currentColor"
                      strokeWidth="0.992949"
                    />
                  </svg>
                  <span>Email us</span>
                </a>{" "}
                <a
                  href="mailto:enquiries@thevincenthotel.com"
                  title="Call The Vincent Hotel, Southport">
                  enquiries@thevincenthotel.com
                </a>
              </li>
              <li className="info call">
                <a
                  href="tel:+441704883800"
                  title="Call The Vincent Hotel, Southport"
                  className="button">
                  <svg
                    width="20"
                    height="26"
                    viewBox="0 0 20 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.03845 1.52658L5.42191 1.11377C6.71922 0.727369 8.10524 1.35258 8.66068 2.57419L9.76642 5.00782C10.2473 6.06743 9.97985 7.31425 9.10555 8.08946L6.66907 10.2471C6.8195 11.5383 7.28494 12.8091 8.0641 14.0595C8.804 15.2693 9.79646 16.3282 10.9827 17.1736L13.9091 16.2616C15.0174 15.9171 16.226 16.3144 16.9048 17.2468L18.4902 19.4188C19.2809 20.5036 19.1382 21.9988 18.1571 22.918L17.1067 23.9032C16.0601 24.8837 14.5648 25.2401 13.1826 24.8369C9.91685 23.8864 6.91722 21.0652 4.1786 16.3732C1.43612 11.6739 0.467956 7.68505 1.2754 4.41141C1.61483 3.0338 2.66528 1.93578 4.03845 1.52658Z"
                      stroke="currentColor"
                      strokeWidth="0.992949"
                    />
                  </svg>
                  <span>Call us</span>
                </a>{" "}
                <a
                  href="tel:+441704883800"
                  title="Call The Vincent Hotel, Southport">
                  01704 883 800
                </a>
              </li>
            </ul>
          </div>
        </section>
        <section className="rhs tableBooking">
          <h5>Alternatively, use the ONLINE BOOKING SERVICE</h5>

          <div
            id="rd-widget-frame"
            style={{ maxWidth: "600px", margin: "auto" }}
          />

          <input
            id="rdwidgeturl"
            name="rdwidgeturl"
            value="https://booking.resdiary.com/widget/Standard/VCafeSushiBar/39194?includeJquery=false"
            type="hidden"
          />
        </section>
      </div>
      <CloseModal setBookModal={setBookModal} />
    </motion.div>
  );
};

export default function BookingsModal() {
  const [bookModal, setBookModal] = useContext(BookContext);

  //get the pageYOffset
  const scrollY = isBrowser() ? window.pageYOffset : 0;

  return (
    <AnimatePresence>
      {bookModal.show && (
        <motion.div
          variants={shrowdVariants}
          initial="initial"
          animate="visible"
          exit="exit"
          className={bookingsModalHolder}
          onClick={() => setBookModal({ show: false })}>
          {bookModal.type !== "table" && (
            <BookARoom
              bookModal={bookModal}
              setBookModal={setBookModal}
              scrollY={scrollY}
            />
          )}
          {bookModal.type === "table" && (
            <BookATable
              bookModal={bookModal}
              setBookModal={setBookModal}
              scrollY={scrollY}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
