import React from "react";
import { Link } from "gatsby";
import { whatsOnHolder } from "./WhatsOnBadge.module.scss";
import useWhatsOn from "../hooks/useWhatsOn";
import OfferBadge from "../images/svgs/OfferBadge";

export default function WhatsOnBadge() {
  const whatsOnInfo = useWhatsOn();
  return (
    <>
      {whatsOnInfo.whatsOnPageOptions.showWhatsOnRotatingBadge && (
        <li className={whatsOnHolder}>
          <Link
            to="/whats-on"
            title="What's On at The Vincent"
            activeClassName="active">
            {whatsOnInfo.whatsOnPageOptions.rotatingBadgeText}
          </Link>
          <OfferBadge />
        </li>
      )}
    </>
  );
}
