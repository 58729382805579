import React, { lazy } from "react";
import { SuspenseHelper } from "../SuspenseHelper";

const OnlineBookingForm = lazy(() => import("./OnlineBookingForm"));

export default function LazyOnlineBookingForm() {
  return (
    <div>
      <SuspenseHelper fallback={<p>Loading booking form...</p>}>
        <OnlineBookingForm />
      </SuspenseHelper>
    </div>
  );
}
