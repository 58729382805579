import React, { lazy } from "react";
import { SuspenseHelper } from "../SuspenseHelper";

const RoomBookingForm = lazy(() => import("./RoomBookingForm"));

export default function LazyRoomBookingForm() {
  return (
    <div>
      <SuspenseHelper fallback={<p>Loading form...</p>}>
        <RoomBookingForm />
      </SuspenseHelper>
    </div>
  );
}
