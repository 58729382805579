import React from 'react';

export const InstagramSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.55556 0C2.48778 0 0 2.48778 0 5.55556V14.4444C0 17.5122 2.48778 20 5.55556 20H14.4444C17.5122 20 20 17.5122 20 14.4444V5.55556C20 2.48778 17.5122 0 14.4444 0H5.55556ZM16.6667 2.22222C17.28 2.22222 17.7778 2.72 17.7778 3.33333C17.7778 3.94667 17.28 4.44444 16.6667 4.44444C16.0533 4.44444 15.5556 3.94667 15.5556 3.33333C15.5556 2.72 16.0533 2.22222 16.6667 2.22222ZM10 4.44444C13.0678 4.44444 15.5556 6.93222 15.5556 10C15.5556 13.0678 13.0678 15.5556 10 15.5556C6.93222 15.5556 4.44444 13.0678 4.44444 10C4.44444 6.93222 6.93222 4.44444 10 4.44444ZM10 6.66667C9.11594 6.66667 8.2681 7.01786 7.64298 7.64298C7.01786 8.2681 6.66667 9.11594 6.66667 10C6.66667 10.8841 7.01786 11.7319 7.64298 12.357C8.2681 12.9821 9.11594 13.3333 10 13.3333C10.8841 13.3333 11.7319 12.9821 12.357 12.357C12.9821 11.7319 13.3333 10.8841 13.3333 10C13.3333 9.11594 12.9821 8.2681 12.357 7.64298C11.7319 7.01786 10.8841 6.66667 10 6.66667Z"
      fill="currentColor"
    />
  </svg>
);
export const FacebookSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 6C20 2.68629 17.3137 0 14 0H6C2.68629 0 0 2.68629 0 6V14C0 17.3137 2.68629 20 6 20H10.69V12.2656H8.08667V9.23778H10.69V7.01C10.69 4.42778 12.2689 3.02 14.5733 3.02C15.35 3.01778 16.1256 3.05778 16.8978 3.13667V5.83667H15.3111C14.0556 5.83667 13.8111 6.43 13.8111 7.30556V9.23333H16.8111L16.4211 12.2611H13.7933V20H14C17.3137 20 20 17.3137 20 14V6Z"
      fill="currentColor"
    />
  </svg>
);

export const TelephoneSvg = () => (
  <svg width="20" height="27" viewBox="0 0 20 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 1.49994C4.8 1.09994 5.85526 0.683265 7 0.999938C8.14478 1.31662 9 3.99984 9.5 5.49994C9.78283 6.34847 9.33333 7.1666 9 7.49994L6 10.4999C6.33333 11.6666 7.16671 13.5001 8.5 15.5C9.3 16.7 10.6667 18.3333 11.5 18.9999C11.8333 18.9999 12.8 18.7999 14 17.9999C15.5 16.9999 16.5 17.6666 17 17.9999C17.8333 18.8333 19.5 20.7999 19.5 21.9999C19.5 23.4999 18.5 23.9999 18 24.4999C17.5 24.9999 16 25.9999 14 25.9999C12 25.9999 8.5 22.4999 7.5 21.4999C6.5 20.4999 3.5 15.9999 2 12.4999C0.5 8.99994 1 4.49994 1.5 3.49994C2 2.49994 3 1.99994 4 1.49994Z"
      stroke="currentColor"
    />
  </svg>
);

export const EmailSvg = () => (
  <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="20" height="15" rx="2" stroke="currentColor" />
    <path d="M1.5 2L11.5 10.5L20.5 1.5" stroke="currentColor" />
    <path d="M2 14L7.5 8.5" stroke="currentColor" />
    <path d="M15 8.5L20 14" stroke="currentColor" />
  </svg>
);

export const LocationSvg = () => (
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.405725 9.40653V9.40464C0.405725 4.45883 4.57822 0.405626 9.73503 0.405626C14.8917 0.405626 19.0643 4.45867 19.0643 9.41053L19.0643 9.41242C19.0812 13.0362 16.7431 16.4655 14.3208 19.0307C13.1181 20.3044 11.9129 21.3459 11.0079 22.0691C10.5557 22.4305 10.1793 22.7117 9.91669 22.9021C9.84773 22.9521 9.78663 22.9958 9.7341 23.033C9.04891 22.5539 7.0116 21.0689 5.01151 18.9358C2.63861 16.405 0.388824 13.0366 0.405725 9.40653ZM4.46194 9.41053C4.46194 12.2471 6.8348 14.5214 9.73503 14.5214C12.6353 14.5214 15.0081 12.2471 15.0081 9.41053C15.0081 6.574 12.6353 4.29964 9.73503 4.29964C6.8348 4.29964 4.46194 6.574 4.46194 9.41053Z"
      stroke="currentColor"
      strokeWidth="1"
    />
  </svg>
);
